const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: '#1874D2',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img
        src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZWpxZnNnYjR6Z2Rzcnh5YWN4aDg3Y3JkaHRoeDdka2Vqem1qajJiOCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/TvLuZ00OIADoQ/giphy.gif"
        alt="loading..."
        style={{width: '30%', height: 'auto'}}
      />
    </div>
  );
};

export default Loading;